import './Post.css';
import React from 'react';
import {FaThumbsUp, FaThumbsDown} from "react-icons/fa";

class Post extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="Post-container">
				<header className="Post-header">
					<span>{this.props.title}</span>
					<span className="Post-votes">
						<span className="Post-votes-votes">votes: </span>
						<span className="Post-votes-count">{this.props.votes}</span>
						<span className="Post-votes-action" onClick={this.props.onUpvote}><FaThumbsUp/></span>
						<span className="Post-votes-action" onClick={this.props.onDownvote}><FaThumbsDown/></span>
					</span>
					<span><span className="Post-author">author: </span><span className="Post-author-username">{this.props.username}</span></span>
				</header>
				<div className="Post-body">
					{this.props.content}
				</div>
			</div>
		);
	}
}

export default Post;
