import './Inputs.css';
import React from 'react';

class Inputs extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			username: "",
			title: "",
			content: "",
		}
	}

	handleChange(e) {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	async handleSubmit() {
		if (this.state.username && this.state.title && this.state.content) {
			await fetch(`https://cf-social-network-api.abhishekcs.workers.dev/posts`, {
				method: "post",
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					username: this.state.username,
					title: this.state.title,
					content: this.state.content
				})
			});

			this.setState({
				username: "",
				title: "",
				content: "",
			})

			await this.props.fetchPosts();
		}

	}

	render() {
		return (
			<div className="Posts-input">
				<div><span>Username: </span><input name="username" type="text" value={this.state.username} onChange={this.handleChange.bind(this)}/></div>
				<div><span>Title: </span><input name="title" type="text" value={this.state.title} onChange={this.handleChange.bind(this)}/></div>
				<div><span>Content: </span><textarea name="content" type="text" value={this.state.content} onChange={this.handleChange.bind(this)}/></div>
				<div className="Posts-submit" onClick={this.handleSubmit.bind(this)}>Submit</div>
			</div>
		);
	}
}

export default Inputs;
