import './Posts.css';
import React from 'react';
import Post from './Post';
import Inputs from './Inputs';

class Posts extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			posts: [
			],
			error: null,
		}
	}

	async componentDidMount() {
		await this.fetchPosts();
	}

	async fetchPosts() {
		try {
			const response = await fetch(`https://cf-social-network-api.abhishekcs.workers.dev/posts`);
			const json = await response.json();
			if (json.error) {
				this.setState({error: json.error});	
			} else {
				this.setState({posts: json.sort((p1, p2) => p2.votes - p1.votes)});
			}
		} catch(e) {
			this.setState({error: "Failed to fetch posts"});	
		}
	}

	async onUpvote(id) {
		try {
			const response = await fetch(`https://cf-social-network-api.abhishekcs.workers.dev/posts/${id}/upvote`, { method: "POST" });
		} catch(e) {
			this.setState({error: "Failed to upvote post"});	
		}

		await this.fetchPosts();
	}

	async onDownvote(id) {
		try {
			const response = await fetch(`https://cf-social-network-api.abhishekcs.workers.dev/posts/${id}/downvote`, { method: "POST" });
		} catch(e) {
			this.setState({error: "Failed to downvote post"});	
		}

		await this.fetchPosts();
	}

	render() {
		var postsContent;
		if (this.state.posts.length > 0) {
			postsContent = (
				<div className="Posts-container-inner">
				{this.state.posts.map(post => (
					<Post key={post.id} title={post.title} username={post.username} content={post.content} votes={post.votes} onUpvote={this.onUpvote.bind(this, post.id)} onDownvote={this.onDownvote.bind(this, post.id)}/>	
				))}
				</div>
			);
		} else if (this.state.error) {
			postsContent = (
				<p className="Posts-error">{this.state.error}. Please try again later</p>
			)
		} else {
			postsContent = (
				<p className="Posts-loading">No Posts to show yet...</p>
			)
		}

		return (
			<div className="Posts-container">
				{postsContent}

				<Inputs fetchPosts={this.fetchPosts.bind(this)}/>	
			</div>
		);
	}
}

export default Posts;
