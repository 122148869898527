import './App.css';
import Posts from './Posts';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      	<h1>Social Network</h1> 
      </header>
	<Posts />
    </div>
  );
}

export default App;
